// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-callback-stripe-tsx": () => import("./../../../src/pages/callback-stripe.tsx" /* webpackChunkName: "component---src-pages-callback-stripe-tsx" */),
  "component---src-pages-callback-tsx": () => import("./../../../src/pages/callback.tsx" /* webpackChunkName: "component---src-pages-callback-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kanji-lesen-tsx": () => import("./../../../src/pages/kanji-lesen.tsx" /* webpackChunkName: "component---src-pages-kanji-lesen-tsx" */),
  "component---src-pages-kanji-schreiben-tsx": () => import("./../../../src/pages/kanji-schreiben.tsx" /* webpackChunkName: "component---src-pages-kanji-schreiben-tsx" */),
  "component---src-pages-meinbereich-index-tsx": () => import("./../../../src/pages/meinbereich/index.tsx" /* webpackChunkName: "component---src-pages-meinbereich-index-tsx" */),
  "component---src-pages-meinbereich-kanji-lesen-tsx": () => import("./../../../src/pages/meinbereich/kanji-lesen.tsx" /* webpackChunkName: "component---src-pages-meinbereich-kanji-lesen-tsx" */),
  "component---src-pages-meinbereich-suchen-tsx": () => import("./../../../src/pages/meinbereich/suchen.tsx" /* webpackChunkName: "component---src-pages-meinbereich-suchen-tsx" */),
  "component---src-pages-meinbereich-wiederholen-tsx": () => import("./../../../src/pages/meinbereich/wiederholen.tsx" /* webpackChunkName: "component---src-pages-meinbereich-wiederholen-tsx" */)
}

