import auth0 from "auth0-js"
import { navigate } from "gatsby"

const isBrowser = typeof window !== "undefined"

export class Auth {
  auth0 = isBrowser ? new auth0.WebAuth({
    domain: process.env.AUTH0_DOMAIN,
    clientID: process.env.AUTH0_CLIENTID,
    redirectUri: process.env.AUTH0_CALLBACK,
    responseType: "token id_token",
    scope: "openid profile email",
    language: 'de'
  }) : {};

  accessToken: any
  idToken: any
  expiresAt: any
  userProfile: any

  constructor() {
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.getAccessToken = this.getAccessToken.bind(this)
    this.getIdToken = this.getIdToken.bind(this)
  }

  login = () => {
    if (!isBrowser) {
      return
    }
  
    this.auth0.authorize()
  }

  silentAuth = (callback?: () => any) => {
    if (!this.isAuthenticated()) { 
      if (callback) {
        return callback()
      }
    }
    this.auth0.checkSession({}, this._setSession(callback))
  }

  handleAuthentication() {
    if (!isBrowser) {
      return
    }

    this.auth0.parseHash(this._setSession())
  }

  getAccessToken() {
    return this.accessToken
  }

  getIdToken() {
    return this.idToken
  }

  _setSession = (callback?: () => any) => (err: any, authResult: any) => {
    if (!isBrowser) {
      return
    }

    if (err) {
      navigate('/')
      console.error(err)
      if (callback) {
        callback()
      }
      return
    }

    if (authResult && authResult.accessToken && authResult.idToken) {
      // Set isLoggedIn flag in localStorage
      localStorage.setItem('isLoggedIn', 'true')

      // Set the time that the access token will expire at
      const expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
      this.accessToken = authResult.accessToken
      this.idToken = authResult.idToken
      this.expiresAt = expiresAt

      this.auth0.client.userInfo(this.accessToken, (_err: any, profile: any) => {
        if (profile) {
          this.userProfile = profile
        }
        // navigateTo to the home route
        navigate('/meinbereich/')
        if (callback) {
          callback()
        }
      })
    }
  }

  logout() {
    if (!isBrowser) {
      return
    }

    // Remove tokens and expiry time
    this.accessToken = null
    this.idToken = null
    this.expiresAt = 0
    this.userProfile = null

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem('isLoggedIn')
    this.auth0.logout()

    // navigateTo to the home route
    navigate('/')
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = this.expiresAt
    return new Date().getTime() < expiresAt
  }

  getUser() {
    return this.userProfile
  }

  getUserName() {
    if (this.getUser()) {
      return this.getUser().given_name
    }
  }
}

const auth = new Auth();
export default auth;

export const getAccessTokenSilently = () => {
  return auth.getIdToken();
}
